.signstep2 {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets//logimg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -180px;
}

.signstep2 .form {
  width: 400px;
  height: 170px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 25px 10px 10px;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100%;
  max-height: 100%;
}