.treatments_page {
  min-height: calc(100vh - 113.4px);
  display: flex;
  /* align-items: center; */
  padding: 10px;
  justify-content: center;
  background: linear-gradient(90deg, #1413127c, rgba(13, 13, 13, 0.64)),
    url("https://images.pexels.com/photos/6764232/pexels-photo-6764232.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.treatments {
  background-color: white;
  padding: 20px;
  width: min(700px, 100%);
  border-radius: 4px;
  margin: auto;
  max-height: 66vh;
}

.treatments .treatment {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.treatment button:hover {
  color: black;
  background: transparent;
}

.treatment button {
  background-color: #146c43;
  color: white;
  padding: 4px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border: 2px solid #146c43;
  transition: 0.4s ease-in-out;
  font-weight: 500;
}

.confirm {
  /* position: absolute; */
  bottom: 20px;
  left: 20px;
  background-color: #00985f;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-right: auto;
}

.treatments h2,
.banner h2,
.support_s h2 {
  width: fit-content;
  margin: 0px auto 12px;
  padding: 10px 18px 0;
  border-bottom: 0.1px solid rgb(217, 217, 217);
}

.treatments_page table td,
.treatments_page table th {
  font-size: 14px;
  padding: 4px;
}

.treatment_details {
  padding: 5px 3px;
  width: 100%;
  border-bottom: 0.1px solid rgb(230, 230, 230);
}