.popup {
  border-radius: 5px;
  width: min(290px, 100%);
  background: white;
  padding: 25px;
  padding: 10px;
  position: fixed;
  top: 36%;
  box-shadow: 1px 0px 24px -2px #80808047;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 14px;
  gap: 13px;
}

.popup button,
.popup .cancel_o {
  padding: 3px 27px;
  background: none;
  border: 2px solid#00985f;
  background: #00985f;
  transition: 0.5s ease-in-out;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  color: white;
  font-weight: 600;
}


.popup .cancel_o {
  background: #FF3636;
  border-color: #FF3636;
}

.popup button:hover,
.popup .cancel_o:hover {
  background-color: transparent;
  color: black;
}
