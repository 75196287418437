.wallets_user_page {
  background-image: url("../../assets/walletbackimg.jpg");
  min-height: calc(100vh - 113.4px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_wallets {
  background-color: white;
  width: 500px;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 300px;
  max-height: 600px;
  margin: auto;
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
}

.user_wallets>h4 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user_wallets>h4 span {
  font-size: 22px;
  color: #157347;
}

.user_wallets h4>svg {
  font-size: 30px;
  cursor: pointer;
  color: #157347;
}

.wallets>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  flex-wrap: wrap;
}

.wallets>div h4 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wallets>div h4 img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.newwal div label {
  margin-bottom: 10px;
  display: block;
}

.newwal div input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}