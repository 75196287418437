/* https://images.pexels.com/photos/6764687/pexels-photo-6764687.jpeg?auto=compress&cs=tinysrgb&w=600 */

.support_page {
  background: linear-gradient(90deg, #1413127c, rgba(13, 13, 13, 0.64)),
    url("https://images.pexels.com/photos/6764687/pexels-photo-6764687.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10px;
}

.support_s {
  width: min(350px, 100%);
  display: flex;
  flex-direction: column;
  padding: 23px 20px;
  margin: 10px auto;
  background: white;
  box-shadow: 1px 0px 24px -2px rgba(128, 128, 128, 0.429);
  border-radius: 5px;
  max-height: fit-content !important;
  height: fit-content !important;
}

.suppord_card {
  gap: 15px;
  width: 100%;
  justify-content: space-around;
  text-decoration: none;
  color: black;
  padding: 14px 10px;
  border-bottom: 0.1px solid rgb(230, 230, 230);
}

.suppord_card p {
  padding: 0;
  margin: 0;
}

.suppord_card>* {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.suppord_card img {
  width: 39px;
}

.suppord_card,
.suppord_card>* {
  display: flex;
}

.left_side {
  flex: 1;
}