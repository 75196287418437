/* FloatingButton.css */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  z-index: 123;

  height: 60px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-button:hover {
  background-color: #0056b3;
}

.chat-popup {
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 360px;
  max-height: 400px;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 10px;
}


