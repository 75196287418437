.regist_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-image: url("../../assets//logimg.jpg");
  background-size: cover;
}

.registform {
  min-width: 500px;
  min-height: 300px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  max-width: 100%;
  max-height: 100%;
}

.registform form .logsign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.registform form .logsign span {
  /* height: 30px; */
  width: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 200px;
  /* height: 30px; */
  border: none;
  /* max-width: 50%; */
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.registform form .logsign span.active {
  background-color: #0a58ca;
  color: white;
}

.registform form .logsign button:first-of-type {
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
}

.registform form .logsign span:last-of-type {
  border-radius: 4px 0px 0px 4px;
  border-width: 1px 0px 1px 1px;
  cursor: pointer;
}

.sign_form {
  margin-top: 30px;
}

.sign_form form,
.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.sign_form form input,
.form input {
  width: 100%;
  /* height: 30px; */
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px 19px;
  outline: none;
}

.sign_form form button,
.sign_form form span,
.form button {
  background-color: #157347;
  color: white;
  padding: 4px 34px;
  margin-bottom: 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}

.logo_contact {
  text-align: center;
}

.logo_contact .contact {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.logo_contact .contact img {
  width: 30px;
}

/* .logo_contact .contact img {
  width: 30px;
  height: 20px;
} */

video#video {
  width: 240px !important;
  /* height: 200px !important; */
}
*,
.videoCamera > div,
.videoCamera > div > div {
  position: relative;
}

.loaderChecked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 122312;
  background: #000000ab;
  display: flex;
  justify-content: center;
  align-items: center;
}
