.remakepass {
  min-height: calc(100vh - 113.4px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/remakepass.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.remakepass .repass_form {
  width: 500px;
  max-width: 100%;
  margin: auto;
  background-color: white;
  min-height: 300px;
  padding: 10px;
  padding-top: 20px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.remakepass .repass_form>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.remakepass .repass_form>div>div {
  position: relative;
  margin-bottom: 20px;
}

.remakepass .repass_form>div>div input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.remakepass .repass_form>div>div svg {
  cursor: pointer;
  font-size: 19px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.repassbth {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.repassbth button {
  width: 100px;
  padding: 10px;
  border-radius: 4px;
  background-color: #146c43;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}