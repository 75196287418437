.treatmenstlist {
  overflow-y: auto;
  width: 500px !important;
  position: relative;
  min-height: 64vh;
}

.treatmentdiv {
  display: flex;
  margin-bottom: 17px;
  border-radius: 10px;
  min-height: 100px;
  max-width: 100%;
}

.treatmentdiv .treatment_icon {
  width: 50px;
  background-color: #0072bd;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px 10px 0px;
}

.treatment_icon>svg {
  font-size: 30px;
}

.treatmentdiv .treatment_status {
  padding: 10px;
  background-color: #ccc;
  flex: 1;
  border-radius: 15px 0px 0px 15px;
}

.treatmentdiv .treatment_status>div {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  gap: 7px;
}

.treatments {
  position: relative;
}

.plustreat {
  position: sticky;
  bottom: 6px;
  left: 10px;
  background-color: #ffc720;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.plustreat>svg {
  font-size: 42px !important;
  cursor: pointer !important;
}

.filter {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;
  margin-bottom: 10px;
}

.filter .left {
  flex: 1;
  position: relative;
}

.filter .left input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
}

.filter .left button {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: #198754;
  color: white;
  border: none;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.filter .right {
  width: 100px;
}

.filter .right select {
  /* padding: 10px; */
  height: 35px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
}