.loginpage {
  background-image: url("../../assets/sign2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: -180px; */
}

.loginpage form {
  /* height: 400px !important; */
  background-color: white;
  height: fit-content;
  margin: auto;
}

.signlogin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.signlogin h5 {
  /* width: 100px; */
  max-width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: #ccc;
}

.signlogin h5:first-child {
  background-color: #0a58ca;
  color: white;
  border-radius: 0px 4px 4px 0px;
}

.signlogin h5:last-child {
  border-radius: 4px 0px 0px 4px;
}

.registpage.loginpage {
  min-height: 100vh;
}

.registpage form input::placeholder {
  text-align: right;
}

.registpage form > label {
  font-weight: 700;
}
