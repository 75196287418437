.resetpasspage {
  min-height: calc(100vh - 113.4px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/resetpass.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

/* .reset_pass {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.reset_pass_form h4 {
  text-align: center;
  margin-bottom: 10px;
}

.reset_pass_form {
  width: 500px;
  max-width: 100%;
  padding: 10px;
  margin: auto;
  border-radius: 4px;
  min-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.reset_pass {
  max-width: 100%;
}

.reset_pass_form>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reset_pass_form>div input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ccc;
}

.btn_code {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.btn_code button {
  background-color: #157347;
  color: white;
  width: 100px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.rewritecode {
  width: 500px;
  max-width: 100%;
  padding: 10px;
  margin: auto;
  border-radius: 4px;
  min-height: 200px;
  padding-top: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.reworteform>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reworteform>div input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ccc;
}