.user_data_page {
  min-height: calc(100vh - 113.4px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #1413127c, rgba(13, 13, 13, 0.64)),
    url("https://images.pexels.com/photos/6764232/pexels-photo-6764232.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-size: cover;
  background-repeat: no-repeat;
}

.user_data_page .user {
  width: 500px;
  max-width: 100%;
  margin: auto;
  min-height: 300px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
}

/* .user_data_page .user h4 {
  text-align: center;
} */

.userdata>div>h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 22px;
  color: #146c43;
}

.userdata h4>svg {
  cursor: pointer;
  font-size: 32px;
}

.userdata>div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
  margin-top: 25px;
}

.userdata>div h4 {
  font-size: 22px;
}

.userdata>div p {
  font-size: 20px;
}

.edit_user_data input {
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ccc;
}

.edit_user_data>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn_edit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_edit button {
  padding: 10px;
  width: 100px;
  max-width: 100%;
  background-color: #146c43;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}


.rowDiv{
  display:flex;
  gap: 10px;
  align-items: center;
  margin: 20px;
}