.banner {
  min-height: calc(100vh - 100px);
  /* background: linear-gradient(90deg, #e19d36, yellow); */
  background-image: linear-gradient(to top, #000000a0, #0000009f),
    url("https://images.pexels.com/photos/106152/euro-coins-currency-money-106152.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  gap: 20px;
}

.pay_form form {
  width: 100%;
}

.pay_form {
  width: min(700px, 100%);
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  min-height: 315px;
  border-radius: 4px;
  align-items: center;
  padding: 10px;
  /* top: 20%; */
  transition: 0.3s all ease-in-out;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  justify-content: center;
}

/* .pay_form>* {
  width: 100%;
} */
.pay_form.act {
  min-height: 400px;
}

.form_pay,
.to_pay {
  position: relative;
  margin-bottom: 10px;
}

.pay_form form input {
  position: relative;
  width: 100%;
  /* height: 30px; */
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  /* height: 40px; */
  outline: none;
  padding: 10px;
  height: 45px;
}

.form_pay .arrimg,
.to_pay .arrimg {
  position: absolute;
  width: 20px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.payment_methods {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  width: 100%;
  transition: 0.5s all ease-in-out;
  z-index: 555555555555555555;
  background-color: white;
  overflow-y: auto;
  height: 233px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

/* .payment_methods::-webkit-scrollbar{
  width: 10px;
}

.payment_methods::-webkit-scrollbar-thumb, .payment_methods::-webkit-scrollbar-track,.payment_methods::-webkit-scrollbar-track-piece, .payment_methods::-webkit-scrollbar-corner{
  background-color: #e3b708df;
  border-radius: 5px;

} */

.payment_method {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.selectedpay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.money_send {
  position: relative;
  margin: 30px 0px;
}

.money_send svg {
  position: absolute;
  left: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  font-size: 20px;
}

.confirm svg {
  width: 100%;
  font-size: 35px;
  rotate: 2deg;
}

.payment_method.for_time {
  cursor: no-drop;
  color: #888;
}

.confshow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 888;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 170px;
  padding: 10px;
  background-color: white;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  max-width: 100%;
}

.confshow input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.confshow button {
  background-color: #146c43;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confshow > svg {
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.not_info {
  position: fixed;
  top: 130px;
  left: 0px;
  cursor: pointer;
  width: 70px;
}

.notes {
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  background-color: white;
  padding: 30px;
  /* width: 500px; */
  width: min(700px, 100%);
  border-radius: 4px;
  max-width: 100%;
  min-height: 100px;
  max-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notes img {
  width: 60px;
  margin-bottom: 20px;
  /* animation-name: my-animation; */
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* div {
  animation-name: my-animation;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  width: 300px;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

} */

@keyframes my-animation {
  from {
    /* background-color: #ff7a59; */
    scale: 1.1px;
    top: 10px;
  }

  to {
    /* background-color: #33475b; */
    width: 50px;
    top: 100px;
  }
}

.selected_payment_method img {
  max-height: 39px;
}

.slefromwal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: row-reverse;
  margin-bottom: 25px;
}

.slefromwal button {
  padding: 10px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.slefromwal button.active {
  background-color: #0069d9;
  color: white;
}

.sel_div_acc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sel_div_acc button {
  padding: 10px;
  cursor: pointer;
  background-color: #0069d9;
  color: white;
  border: none;
  border-radius: 10px;
}

.sel_one {
  background-color: #ccc;
  padding: 10px;
  width: 100px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.sel_one.active {
  background-color: #146c43;
  color: white;
}
