.registpage {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 30px;
  /* height: calc(100vh - 113.4px); */
  background-image: url("../../assets/sign2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: -180px; */
  min-height: 100vh;
}

.registpage form {
  width: min(400px, 100%);
  /* height: 400px; */
  border-radius: 4px;
  display: flex;
  /* min-height: 100vh; */

  gap: 14px;
  /* align-items: center; */
  /* justify-content: center; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  flex-direction: column;
  /* justify-content: space-around; */
  max-width: 100%;
  max-height: 100%;
  background-color: white;
}

.registpage form input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.registpage form > button {
  background-color: #157347;
  color: white;
  padding: 4px 34px;
  margin-bottom: 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}

.registpage form .logsign {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.registpage form .logsign h5 {
  /* width: 100px; */
  max-width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: #ccc;
}

.registpage form .logsign h5:last-child {
  background-color: #0a58ca;
  color: white;
  border-radius: 4px 0px 0px 4px;
}

.registpage form .logsign h5:first-child {
  border-radius: 0px 4px 4px 0px;
}

.passdiv {
  position: relative;
  width: 100%;
}

.passdiv input {
  width: 100%;
  height: 100%;
}

.passdiv > svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 22px;
  z-index: 999;
}

.registpage form p {
  color: blue;
  cursor: pointer;
  font-size: 22px;
}

.imageUploader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registpage form {
  height: fit-content;
  margin: auto;
}
