.transform-component-module_wrapper__SPB86 {
  margin: auto !important;
}

.newconf {
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 20px;
  align-items: center;
}

.newconf .notes {
  max-width: 100%;
  width: 500px;
  margin: auto;
}