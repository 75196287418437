@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

* {
  font-family: "Tajawal", sans-serif;
}

.confirm_page {
  background: linear-gradient(to top, #10101075, #0c0c0c7a),
    url("https://images.pexels.com/photos/41183/background-british-budget-business-41183.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.confirm_form>div:not(.v_T) {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm_form>div {
  /* border-bottom: 0.1px solid rgb(230, 230, 230); */
}

.confirm_form input {
  height: 40px;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.confirm_form button:last-of-type {
  background-color: #bb2d3b;
}

.confirm_form {
  background-color: white;
  padding: 20px;
  width: min(700px, 100%);
  min-height: 100%;
  border-radius: 5px;
  font-size: 14px;
  margin: 15px;
}

.confirm_form button {
  /* width: 100%; */
  margin: 10px 0px;
  border: none;
  color: white;
  background-color: #157347;
  cursor: pointer;
  font-size: 15px;
  border-radius: 4px;
  padding: 10px 13px;
}

.confirm_form .btns {
  display: flex;
  align-items: center;
}

table thead tr {
  background: rgb(217, 217, 217);
}

table td,
table th {
  padding: 10px;
}

table {
  width: 100%;
  text-align: center;
  margin: 14px 0;
}

.confirm_form>div h3 {
  padding: 10px 0 10px 37px;
  width: fit-content;
}

.confirm_form>div p {
  margin-top: 6px;
  padding: 4px;
}

.confirm_form h2 {
  margin: auto;
  width: fit-content;
  padding: 10px 19px;
  border-bottom: 0.1px solid rgb(230, 230, 230);
}

.copyinput {
  position: relative;
  border: 1px solid #ccc;
  margin-top: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.copyinput input {
  border: none;
  margin: 0px;
}

.copyinput>svg {
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}