.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  max-height: 44vh !important;
  overflow-y: auto;
}

.chat-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  position: relative;
}

.chat-message.own {
  background-color: #dcf8c6;
  align-self: flex-start;
}

.chat-message.other {
  background-color: #fff;
  border: 1px solid #ccc;
  align-self: flex-end;
}

.chat-message strong {
  display: block;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.chat-message span {
  display: block;
  font-size: 0.8em;
  color: #666;
  text-align: right;
}

.chat-input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
}

.chat-input::placeholder {
  font-size: 14px;
}

.chat-send-button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-send-button:hover {
  background-color: #0056b3;
}

.chat-cancel-button {
  padding: 8px 16px;
  border: none;
  background-color: #ff4444;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: .9em;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-cancel-button:hover {
  background-color: #cc0000;
}

.menu-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 123;
}

.menu-container button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #555;
}

.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 23px;
  left: 4px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.menu button {
  padding: 5px 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  color: #555;
}

.menu button:hover {
  background-color: #f0f0f0;
  color: #000;
}


.chat-container{
  width: 100% !important;
}